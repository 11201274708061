import $ from 'jquery';

$(function(){
		"use strict";
	var wid = $(window).width();
	if( wid < 640 ){
		$('.imgChange').each(function(){
			$(this).attr("src",$(this).attr("src").replace('_pc', '_sp'));
		});
	}
});
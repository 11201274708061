import $ from 'jquery';

// superfishとslicknavは副作用にグローバル変数の`jQuery`を参照するため、グローバル変数への設定を行ってから遅延インポートする
if(!globalThis.$) globalThis.$ = $;
if(!globalThis.jQuery) globalThis.jQuery = $;
const superfishPromise = import('superfish');
const slicknavPromise = import('@/javascripts/ui/jquery.slicknav');

globalThis.$(function() {
  superfishPromise.then(() => globalThis.$('ul.sf-menu').superfish());
  slicknavPromise.then(() => globalThis.$('#menu').slicknav());
});

import $ from 'jquery';

// JavaScript Document
$(function() {
  var top_element = document.getElementsByClassName("gotop")[0];
  // gotopが存在しないviewでは処理をぬける
  if (!top_element) { return }
  var top_element_height = top_element.offsetHeight;
  // ページトップボタンを非表示にする
  top_element.style.display = "none";
  top_element.style.height = "0";
  // lead_element要素を徐々にスライドさせて非表示にする
  function topSlideUp() {
    if (top_element.offsetHeight > 0) {
      top_element.style.height = (top_element.offsetHeight - 19) + "px";
    } else {
      top_element.style.height = 0;
      top_element.style.display = "none";
    }
  }

  function topSlideDown() {
    if (top_element.offsetHeight < top_element_height) {
      top_element.style.height = (top_element.offsetHeight + 15) + "px";
    } else {
      top_element.style.height = top_element_height + "px";
    }
  }

  window.addEventListener("scroll", function() {
    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    // スクロール位置が100よりも小さい場合に以下の処理をする
    if (scrollTop > 100) {
      // (100より小さい時は)ページトップボタンをスライドダウン
      top_element.style.display = "block";
      topSlideDown();
    } else {
      // それ以外の場合の場合はスライドアップする。
      topSlideUp();
    }

    if (scrollTop == 0){
      top_element.style.height = 0;
      top_element.style.display = "none";
    }
    // フッター固定する
    // ドキュメントの高さ
    var scrollHeight = this.document.documentElement.scrollHeight;
    //　ウィンドウの高さ+スクロールした高さ→　現在のトップからの位置
    var scrollPosition = document.documentElement.clientHeight + scrollTop;
    // フッターの高さ
    var footHeight = document.getElementById("footer").clientHeight;

    if ( scrollHeight - scrollPosition  <= footHeight ) {
      // 現在の下から位置が、フッターの高さの位置にはいったら
      //  ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
      top_element.style.position = "absolute";
      top_element.style.bottom = footHeight;
    } else {
      // それ以外の場合は元のcssスタイルを指定
      top_element.style.position = "fixed";
    }
  });
});

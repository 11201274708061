import $ from 'jquery';

$(document).ready(function(){
  $(".InThePageBtn_followArea").hide();
  var hiddenFavoriteBarPosition = $(".InThePageBtn_wrap").height() + $("header").height()
  $(window).on("scroll", function() {
    const InnerscrollPosition = $(window).height() + $(window).scrollTop();
    if ( InnerscrollPosition <= hiddenFavoriteBarPosition && $(window).scrollTop() > 60 ) {
      $('.InThePageBtn_followArea').slideDown('fast');
      $(".InThePageBtn_followArea").css({ "position":"fixed", "bottom": "0px" });
    } else {
      $('.InThePageBtn_followArea').slideUp('fast');
    }
  });
});
import $ from 'jquery';

// スティッキーヘッダー
$(function () {
  /** ウィンドウ */
  const $window = $(window);
  /** #content部分 */
  const $content = $(".cont_area");
  /** #change部分(ヘッダー) */
  const $change = $("#change");
  /** #contentの位置を取得 */
  const topContent = $content.offset().top;
  /** ヘッダーの高さ */
  const headerHeight = $change.outerHeight();
  /** スティッキー状態フラグ */
  let sticky = false;

  // ウィンドウスクロール時のイベント
  $window.on("scroll", function () {
    if ($window.scrollTop() > topContent) {
      // ヘッダーの高さ分だけ、アンカーリンクのスクロールマージンを設定
      $(':target').css('scroll-margin-top', `${headerHeight + 4}px`);
      if (sticky === false) {
        //scroll位置が#contentの下にある場合、ヘッダーを下ろす
        $change.slideDown();
        sticky = true;
      }
    } else {
      // アンカーリンクのスクロールマージンを削除
      $(':target').css('scroll-margin-top', '');
      if (sticky === true) {
        //scroll位置が#contentの上にある場合、ヘッダーを上げる
        $change.slideUp();
        sticky = false;
      }
    }
  });

  $window.trigger("scroll");
});
